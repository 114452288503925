import sanitize from 'sanitize-filename'

const makeDuration = event => {
    const minutes = Math.floor((+new Date(event.end) - +new Date(event.start)) / 60 / 1000)
    return '' + ('0' + Math.floor(minutes / 60)).slice(-2) + ('0' + minutes % 60).slice(-2)
}

const makeTime = time => new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '')

const makeUrl = (base, query) => { return Object.keys(query).reduce(function (accum, key, index) {
    var value = query[key]
    if (value !== null) {
        return '' + accum + (index === 0 ? '?' : '&') + key + '=' + encodeURIComponent(value)
    }
    return accum
}, base) }

const makeFileName = event => {
    return sanitize(`${event.title}`) || 'download'
}

const makeGoogleCalendarUrl = event => makeUrl('https://calendar.google.com/calendar/render', {
    action: 'TEMPLATE',
    dates: makeTime(event.start) + '/' + makeTime(event.end),
    location: event.location,
    text: event.title,
    details: event.description
})

const makeOutlookCalendarUrl = event => makeUrl('https://outlook.live.com/owa', {
    rru: 'addevent',
    startdt: event.start,
    enddt: event.end,
    subject: event.title,
    location: event.location,
    body: event.description,
    allday: false,
    uid: new Date().getTime().toString(),
    path: '/calendar/view/Month'
})

const makeYahooCalendarUrl = event => makeUrl('https://calendar.yahoo.com', {
    v: 60,
    view: 'd',
    type: 20,
    title: event.title,
    st: makeTime(event.start),
    dur: makeDuration(event),
    desc: event.description,
    in_loc: event.location
})

const makeICSCalendarUrl = event => {
    var components = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT'
    ]
    // In case of SSR, document won't be defined
    if (typeof document !== 'undefined') {
        components.push('URL:' + document.URL)
    }
    components.push('DTSTART:' + makeTime(event.start), 'DTEND:' + makeTime(event.end), 'SUMMARY:' + event.title, 'DESCRIPTION:' + event.description, 'LOCATION:' + event.location, 'END:VEVENT', 'END:VCALENDAR')
    return encodeURI('data:text/calendarcharset=utf8,' + components.join('\n'))
}

const makeUrls = event => ([
    { _id: 'apple', title: `Apple`, isBrand: true, icon: 'apple', url: makeICSCalendarUrl(event), downloadFileName: `${makeFileName(event)}.ics` },
    { _id: 'google', title: `Google`, isBrand: true, icon: 'google', url: makeGoogleCalendarUrl(event), downloadFileName: `${makeFileName(event)}.ics` },
    { _id: 'outlook', title: `Outlook`, isBrand: true, icon: 'windows', url: makeOutlookCalendarUrl(event), downloadFileName: `${makeFileName(event)}.ics` },
    { _id: 'yahoo', title: `Yahoo!`, isBrand: true, icon: 'yahoo', url: makeYahooCalendarUrl(event), downloadFileName: `${makeFileName(event)}.ics` },
])

export default makeUrls
