import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Overlay, Popover } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import makeUrls from './makeUrls'
import * as s from './AddToCalendar.scss'

const CalendarButton = ({ item, onClick }) => (
<Button className={s.calendarButton}
    key={`popover-url-button-${item.title}`} 
    variant={null}
    onClick={onClick ? () => onClick() : null}>
    <FontAwesomeIcon name={item.icon} isBrand={item.isBrand} />
</Button>
)

const AddToCalendar = ({ event = {} }) => {

    const [show, setShow] = useState(false)
    const _urls = makeUrls(event) || []
    const _buttonTarget = useRef()

    const handleClick = (_item = {}) => {
        setShow(false)
        var _link = document.createElement('a')
        _link.setAttribute('style', 'display:none;')
        _link.target = '_blank'
        _link.rel = 'noopener noreferrer'
        _link.download = _item.downloadFileName
        _link.href = _item.url
        _link.click()
    }

    return (
        <div className={s.container} onClick={e => e.stopPropagation()}>
            <div className={s.button} ref={_buttonTarget}
                onClick={() => setShow(!show)}>
                <FontAwesomeIcon name='calendar' fixedWidth />
                <div className={s.text}>+add</div>
            </div>
            <Overlay show={show} target={_buttonTarget}    
                placement='right' flip>
            <Popover className={s.popoverContainer} 
                id={`add-to-calendar-popover-${event._id}`}>
                <Popover.Body className={s.popoverContent}>
                {_urls && _urls.map(i =>
                <CalendarButton item={i} onClick={() => handleClick(i)}
                    key={`add-to-calendar-button-${i._id}`} />
                )}
                <Button className={s.calendarButton + ' ' + s.isCloseButton} 
                    onClick={() => setShow(false)}>
                    <FontAwesomeIcon name='times' />
                </Button>
                </Popover.Body>
            </Popover>
            </Overlay>
        </div>
    )
}

export default AddToCalendar
